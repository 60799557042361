// src/store/store.ts
import customMiddleware from './middleware/thunk'; // If you're using Redux Thunk middleware
import rootReducer from './reducers';
import { persistStore } from 'redux-persist';
import { configureStore } from "@reduxjs/toolkit";


export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false, // Required for redux-persist
    }).concat(customMiddleware)
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;