
import Axios from 'axios';
import storage from '../helpers/storage';
import { toast } from 'react-toastify';
import { API_PATH } from '../constants/api-path';
import { refreshTokenAPI } from '../helpers/utils';
import React from 'react';

const skipToastOnSuccess = [
    API_PATH.ADMIN.ADMIN_PROFILE,
    API_PATH.ADMIN_USERS.DETAIL,
    API_PATH.ADMIN_USERS.LISTING,
    API_PATH.ADMIN_USERS.ROLES_LIST,
    API_PATH.BRAND.DETAIL,
    API_PATH.BRAND.LISTING,
    API_PATH.COMMON.COUNTRIES,
    API_PATH.COMMON.STATES,
    API_PATH.COMMON.SALES_CHANNEL,
    API_PATH.PAYMENT_TERM.DETAIL,
    API_PATH.PAYMENT_TERM.LISTING,
    API_PATH.ROLES.DETAIL,
    API_PATH.ROLES.LISTING,
    API_PATH.ROLES.PERMISSION_LIST,
    API_PATH.SALESCHANNEL.LISTING,
    API_PATH.SALESCHANNEL.HISTORY,
    API_PATH.SHIPPING_TERM.DETAIL,
    API_PATH.SHIPPING_TERM.LISTING,
    API_PATH.WAREHOUSE.LISTING,
    API_PATH.WAREHOUSE.VIEW,
    API_PATH.WAREHOUSE.HISTORY,
    API_PATH.VENDOR.LISTING,
    API_PATH.VENDOR.PAYMENT_TERMS,
    API_PATH.VENDOR.SHIPPING_TERMS,
    API_PATH.VENDOR.VIEW,
    API_PATH.VENDOR.HISTORY,
    API_PATH.ORDER.LISTING,
    API_PATH.ORDER.DETAIL,
    API_PATH.ORDER.PICK_LIST,
    API_PATH.ORDER.PACKING_SLIP,
    API_PATH.ORDER.RETURN_LABEL,
    API_PATH.ORDER.HISTORY,
    API_PATH.ORDER.CREATE_DUPLICATE_ORDER,
    API_PATH.ORDER.ADDITIONAL_SHIP_RATE,
    API_PATH.ORDER.ARCHIVE_LIST,
    API_PATH.ORDER.ARCHIVE_DETAIL,
    API_PATH.PRODUCT.BRAND_LIST,
    API_PATH.PRODUCT.BUYER_LIST,
    API_PATH.PRODUCT.SALES_CHANNEL_LIST,
    API_PATH.PRODUCT.VENDOR_LIST,
    API_PATH.PRODUCT.LISTING,
    API_PATH.PRODUCT.CORE_PRODUCT_LIST,
    API_PATH.PRODUCT.VIEW,
    API_PATH.PRODUCT.HISTORY,
    API_PATH.PURCHASE_ORDER.LISTING,
    API_PATH.PURCHASE_ORDER.VENDOR_LIST,
    API_PATH.COMMON.BUYER_LIST,
    API_PATH.COMMON.VENDOR_LIST,
    API_PATH.COMMON.WAREHOUSE_LIST,
    API_PATH.PURCHASE_ORDER.DETAIL,
    API_PATH.COMMON.PO_PRODUCT_LIST,
    API_PATH.PURCHASE_ORDER.HISTORY,
    API_PATH.SALESCHANNEL.CHECKSHOPIFYSALESCHANNELEXIST,
    API_PATH.INVENTORY.LISTING,
    API_PATH.INVENTORY.IN_TRANSIT_IN,
    API_PATH.INVENTORY.PRODUCT_HISTORY_LOG,
    API_PATH.INVENTORY.HISTORY_LOG,
    API_PATH.INVENTORY.ALLOCATED_DETAIL,
    API_PATH.COMMON.WAREHOUSE_PRODUCT_LIST,
    API_PATH.SHIPPINGPROVIDER.LISTING,
    API_PATH.BRAND_REPORTING.LISTING,
    API_PATH.COMMON.BRAND_LIST,
    API_PATH.FBA_FORECASTING.LISTING,
    API_PATH.INVENTORY_VALUE.LISTING,
    API_PATH.INVENTORY.AVAILABILITY_DETAIL,
    API_PATH.ORDERBOTS.BOTS_GET_API,
    API_PATH.ORDERBOTS.LISTING,
    API_PATH.COMMON.PRODUCT_LIST,
    API_PATH.ORDERBOTS.DETAIL,
    API_PATH.ORDERBOTS.HISTORY,
    API_PATH.COMMON.CARRIER_LIST,
    API_PATH.SHIPMENT.LISTING,
    API_PATH.SHIPMENT.BATCH_NO,
    API_PATH.PROFATIBILITY_REPORT.LISTING,
    API_PATH.SHIPMENT.MANIFESTLISTING
];



/**
 * Token Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config: any) {
    const token = storage.getToken();
    if (token) {
        config.headers.authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
}


const httpClient = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor
httpClient.interceptors.request.use(authRequestInterceptor);

// Add a response interceptor
httpClient.interceptors.response.use(
    async (response: any) => {
        const prevTimestamp = storage.getData('timestamp');
        const currentTimestamp = Date.now();
        const diffTimestamp = (currentTimestamp-prevTimestamp)/1000;  // Milis to Sec

        const inactivityTimestamp = Number(process.env.REACT_APP_REFRESH_TOKEN_SEC ?? '43200'); // 12 Hours - 43200;
        
        storage.setData('timestamp', Date.now());

        if(diffTimestamp > inactivityTimestamp) {
            await refreshTokenAPI();
        }

        if (!skipToastOnSuccess.find(stos => response?.config?.url?.includes(stos)) || response?.config?.url?.includes(API_PATH.PRODUCT.UPDATE_STATUS_VENDOR)) {
            if (response?.data?.msg) {
                toast(
                    React.createElement('div', { dangerouslySetInnerHTML: { __html: response?.data?.msg } }),
                    {
                        type: toast.TYPE.SUCCESS,
                        autoClose: 3000
                    }
                );
            }
        }

        if(response?.config?.url?.includes(API_PATH.ORDER.RETURN_LABEL)) {
            if (response?.data?.msg) {
                let text = '';

                if(response?.data?.data?.successOrders?.length > 0) {
                    text += `<div class="mb-2"><b>Return Label Fetched Successfully for Orders</b><br />`;
                    response?.data?.data?.successOrders.forEach((order: any) => {
                        text += `<div class="mb-1"><b>${order?.orderId}</b></div>`;
                    });
                    text += `</div>`;
                }

                if(response?.data?.data?.failedOrders?.length > 0) {
                    text += `<div class="mb-2"><b>Could Not Create Return Label for Orders</b><br />`;
                    response?.data?.data?.failedOrders.forEach((order: any) => {
                        text += `<div class="mb-1"><b>${order?.orderId}</b> : ${order?.message}</div>`;
                    });
                    text += `</div>`;
                }

                toast(
                    React.createElement('div', { dangerouslySetInnerHTML: { __html: text } }),
                    {
                        type: response?.data?.data?.failedOrders?.length > 0 ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
                        autoClose: 3000
                    }
                );
            }
        }

        // You can modify the response here (e.g., data transformation)
        return response?.data;
    },
    (error) => {

        toast.dismiss();

        if (
            error?.response?.data?.msgCode === '50049'
        ) {
            return Promise.reject(error.response);
        }

        if (
            error?.response?.data?.msgCode === 50033
        ) {

            window.location.href = '/app/dashboard';

            return;
        }

        if(error?.response?.data?.msg) {
            toast(
                React.createElement('div', { dangerouslySetInnerHTML: { __html: error?.response?.data?.msg } }),
                {
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                }
            );
        }
        
        if (
            error?.response?.data?.msgCode === 50017 ||
            error?.response?.data?.msgCode === 50018
        ) {

            storage.clearToken();
            storage.clearData('filters');
            storage.clearData('currentModule');
            storage.removePermissionAndLabel();

            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);
        }
        // You can handle errors globally here
        return Promise.reject(error.response);
    }
);

export default httpClient;
