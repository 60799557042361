// src/reducers/index.ts
import { combineReducers } from 'redux';
import themeReducer from './theme';
import settingReducer from './global-setting';
import brands from './brands';
import permissions from './permissions';
// Import other reducers as needed

const rootReducer = combineReducers({
    theme: themeReducer,
    brand: brands,
    permissions,
    settings: settingReducer
    // Add other reducers here
});

export default rootReducer;
