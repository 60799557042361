import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { GLOBAL_DEFAULT } from '../../constants/global';
import { fetchBrands } from '../actions/brand-actions';

interface BrandState {
    brands: any;
    currentPage: number;
    filters: any;
    rows: number;
    sortField: string;
    sortOrder: string;
    loading: boolean;
    error: any;
}

const initialState: BrandState = {
    brands: [],
    currentPage: GLOBAL_DEFAULT.CURRENT_PAGE,
    filters: {
        search: '',
        status: GLOBAL_DEFAULT.STATUS.ACTIVE
    },
    rows: GLOBAL_DEFAULT.ROW_PER_PAGE_100,
    sortField: GLOBAL_DEFAULT.SORT_FIELD,
    sortOrder: GLOBAL_DEFAULT.SORT_ORDER,
    loading: false,
    error: null
};

const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<any>) => {
            state.filters.status = action?.payload?.status;
            state.filters.search = action?.payload?.search || '';
        },
        updatePaginator: (state, action: PayloadAction<any>) => {
            state.currentPage = action?.payload?.page;
            state.rows = action?.payload?.rows;
        },
        updateSorting: (state, action: PayloadAction<any>) => {
            state.sortOrder = action?.payload?.sortOrder;
            state.sortField = action?.payload?.sortField;
        }
    },
    extraReducers: (builder: any) => {
        builder
            .addCase(fetchBrands.pending, (state: any) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchBrands.fulfilled, (state: any, action: any) => {
                state.loading = false;
                state.brands.push(action.payload); // Add new product to state
            })
            .addCase(fetchBrands.rejected, (state: any, action: any) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { updateFilters, updatePaginator, updateSorting } = brandSlice.actions;

// Persist Configuration
const persistConfig = {
    key: 'brand',
    storage: storageSession, // Change to 'storage' for localStorage persistence
};

export default persistReducer(persistConfig, brandSlice.reducer);
