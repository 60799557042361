export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const REACT_TABLE_ORDER = "DESC";
export const CUSTOM_PAGINATOR_VALUES = [10, 20, 50, 100];
export const PAGINATOR_ROWS_DEF = 10;
export const PAGINATOR_FIRST = 0;
export const HIDE_TABLE_PAGINATOR = true;
export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,15}$/;
export const NAME_REGEX = /^(?!\s)(?!.*\s$)(.{1,255})$/;
export const ROLE_NAME_REGEX = /^(?!\s)[A-Za-z](?: [A-Za-z]|[A-Za-z]){2,29}(?<!\s)$/;
export const SKU_REGEX = /^(?!\s)(?!.*\s$)(.{1,255})$/;
export const PRODUCT_NAME_REGEX = /^(?!\s)(?!.*\s$)(.{1,255})$/;
export const HWL_REGEX = /^\d{1,5}(\.\d)?$/;
export const WEIGHT_OZ_REGEX = /^\d{1,5}(\.\d{1,2})?$/;
export const AMOUNT_REGEX = /^\d{1,7}(\.\d{1,7})?$/;
export const PROD_DESC_REGEX = /^.{0,2000}$/;
export const ORDER_QTY_REGEX = /^\d{1,5}$/;
export const MASTER_BOX_QTY_REGEX = /^[1-9]\d{0,4}$/;
export const DAYS_REGEX = /^(?:36[0-5]|3[0-5]\d|[1-2]?\d?\d)$/;
export const VENDOR_COST_REGEX = /^\d{1,5}(\.\d{1,2})?$/;
export const ONLY_NUMBERS_REGEX = /^\d+$/;
export const QTY_REGEX = /^(?:[1-9]\d{0,4}|100000)$/;
export const TEN_DIGITS = /^\d{1,10}$/;
export const GROUP_REGEX = /^[1-9]$/;
export const ZIP_REGEX = /^\d{4,10}$|^[A-Za-z\d\- ]{4,10}$/; // Older regex: /^[a-zA-Z0-9]{1,10}$/;
export const PHONE_REGEX = /^\d{1,15}$/;

export const ALLOWEDFILETYPES = "Allowed file types are";
export const MAX_ALLOWED_FILES_SIZE_ALLOWED = "It seems that one of your file exceed maximum size of 5 MB.";
export const POP_UP_CLOSE_2000 = 2000;
export const MAX_FIVE_FILES_ALLOWED = "Maximum five files are allowed.";
export const SELLERID_REGEX = /^[A-Za-z0-9]{1,255}$/;
export const GLOBAL_DEFAULT = {
    CURRENT_PAGE: 1,
    LOADER_STATE: false,
    MODAL_STATE: false,
    PAGINATOR_ROWS: 10,
    PAGINATOR_FIRST: 0,
    SEARCH_STATE: "",
    SORT_FIELD: 'id',
    SORT_ORDER: 'DESC',
    ROW_PER_PAGE_10: 10,
    ROW_PER_PAGE_20: 20,
    ROW_PER_PAGE_50: 50,
    ROW_PER_PAGE_100: 100,
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2
    }
}