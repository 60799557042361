import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import MainLayout from "../components/common/layouts/MainLayout";
import { lazyImport } from "../helpers/lazyImport";

const Dashboard = lazy(() => import("../pages/dashboard"));
const Profile = lazy(() => import("../pages/profile"));

const { Settings } = lazyImport(
  () => import("../pages/settings/routes"),
  "Settings"
);

const { ProductsManagementRoutes } = lazyImport(
  () => import("../pages/product-management/routes"),
  "ProductsManagementRoutes"
);

const { PurchaseOrdersRoutes } = lazyImport(
  () => import("../pages/purchase-order-management/routes"),
  "PurchaseOrdersRoutes"
);

const { OrdersRoutes } = lazyImport(
  () => import("../pages/order-management/orders/routes"),
  "OrdersRoutes"
);

/* const { ArchiveOrdersRoutes } = lazyImport(
  () => import("../pages/order-management/archive/routes"),
  "ArchiveOrdersRoutes"
); */

const { InventoryRoutes } = lazyImport(
  () => import("../pages/inventory-management/routes"),
  "InventoryRoutes"
);

const { AnalyticsRoutes } = lazyImport(
  () => import("../pages/analytics/routes"),
  "AnalyticsRoutes"
);

const { ShipmentsRoutes } = lazyImport(
  () => import("../pages/shipments/routes"),
  "ShipmentsRoutes"
);

export const protectedRoutes: RouteObject[] = [
  {
    path: '/app',
    element: <MainLayout />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: "profile", element: <Profile /> },
      { path: "settings/*", element: <Settings /> },
      { path: 'products/*', element: <ProductsManagementRoutes /> },
      { path: 'purchase-order/*', element: <PurchaseOrdersRoutes /> },
      { path: 'orders/*', element: <OrdersRoutes /> },
      // { path: 'archive-orders/*', element: <ArchiveOrdersRoutes /> },
      { path: 'inventory/*', element: <InventoryRoutes /> },
      { path: 'analytics/*', element: <AnalyticsRoutes /> },
      { path: 'shipments/*', element: <ShipmentsRoutes /> },
    ],
  },
];

