import { InputText } from "primereact/inputtext";
import { FC } from 'react';
import Form from 'react-bootstrap/Form';
interface IThemeInputText {
    controlId?: string;
    label?: any;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    inputClassName?: any;
    inputWrapClass?: any;
    inputIcon?: any;
    disabled?: boolean;
    wrapperClassname?: any;
    keyfilter?: any;
    autoFocus?: any;
}

/**
 * Input Type Text Reusable Component
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; inputClassName: any; inputWrapClass: any; inputIcon: any; disabled: any; wrapperClassname: any; }} param0
 * @param {*} param0.controlId
 * @param {*} param0.label
 * @param {*} param0.placeholder
 * @param {*} param0.handleChange
 * @param {*} param0.handleBlur
 * @param {*} param0.errorsField
 * @param {*} param0.touched
 * @param {*} param0.value
 * @param {*} param0.inputClassName
 * @param {*} param0.inputWrapClass
 * @param {*} param0.inputIcon
 * @param {*} param0.disabled
 * @param {*} param0.wrapperClassname
 * @returns
 */

const ThemeInputText: FC<IThemeInputText> = ({
    controlId,
    label,
    placeholder,
    handleChange,
    handleBlur,
    errorsField,
    touched,
    value,
    inputClassName,
    inputWrapClass,
    inputIcon,
    disabled,
    wrapperClassname,
    keyfilter,
    autoFocus
}) => {
    return (
        <Form.Group className={wrapperClassname}>
            {label ?
                <Form.Label htmlFor={controlId} className={`mb-1 ${inputClassName === 'theme-inputtext-lg' ? '' : 'small'}`}>{label}</Form.Label>
                : ''
            }
            <div className={`position-relative w-100 ${inputWrapClass ? inputWrapClass : ''}`}>
                {inputIcon ? <i>{inputIcon}</i> : ''}
                <InputText
                    id={controlId}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange(controlId)}
                    className={errorsField && touched ? `p-invalid is-invalid w-100 ${inputClassName ? inputClassName : ''}` : `w-100 ${inputClassName ? inputClassName : ''}`}
                    onBlur={handleBlur(controlId)}
                    disabled={disabled}
                    keyfilter={keyfilter}
                    autoFocus={autoFocus}
                    autoComplete={autoFocus && "off"}
                />
            </div>
            <Form.Control.Feedback type="invalid" className={errorsField && touched ? 'd-block' : 'd-none'}>
                {errorsField}
            </Form.Control.Feedback>

        </Form.Group>
    );
}

export default ThemeInputText