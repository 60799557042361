import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

interface PermissionState {
    analytics: any;
    brand: any;
    inventory: any;
    order: any;
    orderbot: any;
    paymentTerm: any;
    product: any;
    purchaseOrder: any;
    roleRight: any;
    salesChannel: any;
    shipment: any;
    shippingTerm: any;
    user: any;
    vendor: any;
    warehouse: any;
}

const initialState: PermissionState = {
    analytics: {},
    brand: {},
    inventory: {},
    order: {},
    orderbot: {},
    paymentTerm: {},
    product: {},
    purchaseOrder: {},
    roleRight: {},
    salesChannel: {},
    shipment: {},
    shippingTerm: {},
    user: {},
    vendor: {},
    warehouse: {},
};

const ANALYTICS_MANAGEMENT = [
    { name: "BRAND-REPORTING-EXPORT", key: "brand_reporting_export" },
    { name: "FBA-FORECASTING-EXPORT", key: "fba_forecasting_export" },
    { name: "INVENTORY-VALUE-EXPORT", key: "inventory_value_export" },
    { name: "PROFITBILITY-EXPORT", key: "profitability_export" }
];

const BRAND_MANAGEMENT = [
    { name: "BRAND-ADD", key: "add" },
    { name: "BRAND-EDIT", key: "edit" },
    { name: "BRAND-LIST", key: "list" },
    { name: "BRAND-STATUS", key: "status" },
    { name: "BRAND-VIEW", key: "view" },
    { name: "BRAND-DELETE", key: "delete" },
    { name: "BRAND-RESTORE", key: "restore" },
];

const INVENTORY_MANAGEMENT = [
    { name: "INVENTORY-VIEW", key: "view" },
    { name: "INVENTORY-CLEAR", key: "clear" },
    { name: "INVENTORY-EXPORT-CSV", key: "export_csv" },
    { name: "INVENTORY-ADD-STOCK", key: "add_stock" },
    { name: "INVENTORY-DEDUCT-STOCK", key: "deduct_stock" },
];

const ORDER_MANAGEMENT = [
    { name: "ORDER-ADD", key: "add" },
    { name: "ORDER-EDIT", key: "edit" },
    { name: "ORDER-VIEW", key: "view" },
    { name: "ORDER-PRINT-PACKING-SLIP", key: "print_packing_slip" },
    { name: "ORDER-EXPORT", key: "export" },
    { name: "ORDER-DUPLICATE", key: "duplicate" },
    { name: "ORDER-VIEW-HISTORY", key: "view_history" },
    { name: "ORDER-EDIT-RECIPIENT", key: "edit_recipient" },
    { name: "ORDER-SHIP", key: "ship" },
    { name: "ORDER-PRINT-PICK-LIST", key: "print_pick_list" },
    { name: "ORDER-RETURN-LABEL", key: "return_label" },
    { name: "ORDER-ASSIGN-TO", key: "assign_to" },
    { name: "ORDER-HOLD", key: "hold" },
    { name: "ORDER-RESTORE", key: "restore" },
    { name: "ORDER-CANCEL", key: "cancel" },
    { name: "ORDER-LIST-MANIFEST", key: "list_manifest" },
    { name: "ORDER-CREATE-MANIFEST", key: "create_manifest" },
];

const ORDERBOT_MANAGEMENT = [
    { name: "ORDERBOT-ADD", key: "add" },
    { name: "ORDERBOT-EDIT", key: "edit" },
    { name: "ORDERBOT-STATUS", key: "status" },
    { name: "ORDERBOT-DELETE", key: "delete" },
    { name: "ORDERBOT-VIEW-HISTORY", key: "view_history" },
];

const PAYMENT_TERM_MANAGEMENT = [
    { name: "PAYMENT-TERM-ADD", key: "add" },
    { name: "PAYMENT-TERM-EDIT", key: "edit" },
    { name: "PAYMENT-TERM-STATUS", key: "status" },
    { name: "PAYMENT-TERM-DELETE", key: "delete" },
    { name: "PAYMENT-TERM-RESTORE", key: "restore" },
];

const PRODUCT_MANAGEMENT = [
    { name: "PRODUCT-ADD", key: "add" },
    { name: "PRODUCT-EDIT", key: "edit" },
    { name: "PRODUCT-DELETE", key: "delete" },
    { name: "PRODUCT-RESTORE", key: "restore" },
    { name: "PRODUCT-STATUS", key: "status" },
    { name: "PRODUCT-VIEW", key: "view" },
    { name: "PRODUCT-VIEW-HISTORY", key: "view_history" },
    { name: "PRODUCT-EXPORT", key: "export" },
];

const PURCHASE_ORDER_MANAGEMENT = [
    { name: "PURCHASE-ORDER-ADD", key: "add" },
    { name: "PURCHASE-ORDER-EDIT", key: "edit" },
    { name: "PURCHASE-ORDER-VIEW", key: "view" },
    { name: "PURCHASE-ORDER-VOID", key: "void" },
    { name: "PURCHASE-ORDER-RECEIVE", key: "receive" },
    { name: "PURCHASE-ORDER-VIEW-HISTORY", key: "view_history" },
    { name: "PURCHASE-ORDER-RESEND", key: "resend" },
    { name: "PURCHASE-ORDER-CONFIRMATION", key: "confirmation" },
    { name: "PURCHASE-ORDER-PRINT", key: "print" },
    { name: "PURCHASE-ORDER-EXPORT", key: "export" },
    { name: "PURCHASE-ORDER-AUTHORIZE", key: "authorize" },
    { name: "PURCHASE-ORDER-REAUTHORIZE", key: "reauthorize" },
];

const ROLE_RIGHT_MANAGEMENT = [
    { name: "ADMIN-ROLE-ADD", key: "add" },
    { name: "ADMIN-ROLE-EDIT", key: "edit" },
    { name: "ADMIN-ROLE-DELETE", key: "delete" },
];

const SALES_CHANNEL_MANAGEMENT = [
    { name: "SALES-CHANNEL-EDIT", key: "edit" },
    { name: "SALES-CHANNEL-VIEW-HISTORY", key: "view_history" },
    { name: "SALES-CHANNEL-STATUS", key: "status" },
    { name: "SALES-CHANNEL-ADD", key: "add" },
];

const SHIPMENT_MANAGEMENT = [
    { name: "SHIPMENT-EXPORT", key: "export" },
    { name: "SHIPMENT-PRINT-PICK-LIST", key: "print_pick_list" },
    { name: "SHIPMENT-PRINT-PACKING-SLIP", key: "print_packing_slip" },
    { name: "SHIPMENT-VOID", key: "void" },
];

const SHIPPING_TERM_MANAGEMENT = [
    { name: "SHIPPING-TERM-ADD", key: "add" },
    { name: "SHIPPING-TERM-EDIT", key: "edit" },
    { name: "SHIPPING-TERM-STATUS", key: "status" },
    { name: "SHIPPING-TERM-DELETE", key: "delete" },
    { name: "SHIPPING-TERM-RESTORE", key: "restore" },
];

const USERS_MANAGEMENT = [
    { name: "USER-ADD", key: "add" },
    { name: "USER-EDIT", key: "edit" },
    { name: "USER-STATUS", key: "status" },
    { name: "USER-VIEW", key: "view" },
    { name: "USER-DELETE", key: "delete" },
    { name: "USER-RESTORE", key: "restore" },
];

const VENDOR_MANAGEMENT = [
    { name: "VENDOR-ADD", key: "add" },
    { name: "VENDOR-EDIT", key: "edit" },
    { name: "VENDOR-STATUS", key: "status" },
    { name: "VENDOR-VIEW", key: "view" },
    { name: "VENDOR-VIEW-HISTORY", key: "view_history" },
];

const WAREHOUSE_MANAGEMENT = [
    { name: "WAREHOUSE-ADD", key: "add" },
    { name: "WAREHOUSE-EDIT", key: "edit" },
    { name: "WAREHOUSE-STATUS", key: "status" },
    { name: "WAREHOUSE-VIEW", key: "view" },
    { name: "WAREHOUSE-VIEW-HISTORY", key: "view_history" },
];

const setAnalyticsPermissions = (action: any) => {
    const analyticsPermissions: any = {};
    
    ANALYTICS_MANAGEMENT.forEach((permission: any) => {    
        if(action?.payload === "SUPER_ADMIN") {
            analyticsPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                analyticsPermissions[permission?.key] = true;
            } else {
                analyticsPermissions[permission?.key] = false;
            }
        }
    });

    return analyticsPermissions;
}

const setBrandPermissions = (action: any) => {
    const brandPermissions: any = {};
    
    BRAND_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            brandPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                brandPermissions[permission?.key] = true;
            } else {
                brandPermissions[permission?.key] = false;
            }
        }
    });

    return brandPermissions;
}

const setInventoryPermissions = (action: any) => {
    const inventoryPermissions: any = {};
    
    INVENTORY_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            inventoryPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                inventoryPermissions[permission?.key] = true;
            } else {
                inventoryPermissions[permission?.key] = false;
            }
        }
    });

    return inventoryPermissions;
}

const setOrderPermissions = (action: any) => {
    const orderPermissions: any = {};
    
    ORDER_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            orderPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                orderPermissions[permission?.key] = true;
            } else {
                orderPermissions[permission?.key] = false;
            }
        }
    });

    return orderPermissions;
}

const setOrderBotPermissions = (action: any) => {
    const orderbotPermissions: any = {};
    
    ORDERBOT_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            orderbotPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                orderbotPermissions[permission?.key] = true;
            } else {
                orderbotPermissions[permission?.key] = false;
            }
        }
    });

    return orderbotPermissions;
}

const setPaymentTermPermissions = (action: any) => {
    const paymentTermPermissions: any = {};
    
    PAYMENT_TERM_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            paymentTermPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                paymentTermPermissions[permission?.key] = true;
            } else {
                paymentTermPermissions[permission?.key] = false;
            }
        }
    });

    return paymentTermPermissions;
}

const setProductPermissions = (action: any) => {
    const productPermissions: any = {};
    
    PRODUCT_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            productPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                productPermissions[permission?.key] = true;
            } else {
                productPermissions[permission?.key] = false;
            }
        }
    });

    return productPermissions;
}

const setPurchaseOrderPermissions = (action: any) => {
    const purchaseOrderPermissions: any = {};
    
    PURCHASE_ORDER_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            purchaseOrderPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                purchaseOrderPermissions[permission?.key] = true;
            } else {
                purchaseOrderPermissions[permission?.key] = false;
            }
        }
    });

    return purchaseOrderPermissions;
}

const setRoleRightPermissions = (action: any) => {
    const roleRightPermissions: any = {};
    
    ROLE_RIGHT_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            roleRightPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                roleRightPermissions[permission?.key] = true;
            } else {
                roleRightPermissions[permission?.key] = false;
            }
        }
    });

    return roleRightPermissions;
}

const setSalesChannelPermissions = (action: any) => {
    const salesChannelPermissions: any = {};
    
    SALES_CHANNEL_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            salesChannelPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                salesChannelPermissions[permission?.key] = true;
            } else {
                salesChannelPermissions[permission?.key] = false;
            }
        }
    });

    return salesChannelPermissions;
}

const setShipmentPermissions = (action: any) => {
    const shipmentPermissions: any = {};
    
    SHIPMENT_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            shipmentPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                shipmentPermissions[permission?.key] = true;
            } else {
                shipmentPermissions[permission?.key] = false;
            }
        }
    });

    return shipmentPermissions;
}

const setShippingTermPermissions = (action: any) => {
    const shippingTermPermissions: any = {};
    
    SHIPPING_TERM_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            shippingTermPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                shippingTermPermissions[permission?.key] = true;
            } else {
                shippingTermPermissions[permission?.key] = false;
            }
        }
    });

    return shippingTermPermissions;
}

const setUserPermissions = (action: any) => {
    const userPermissions: any = {};
    
    USERS_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            userPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                userPermissions[permission?.key] = true;
            } else {
                userPermissions[permission?.key] = false;
            }
        }
    });

    return userPermissions;
}

const setVendorPermissions = (action: any) => {
    const vendorPermissions: any = {};
    
    VENDOR_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            vendorPermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                vendorPermissions[permission?.key] = true;
            } else {
                vendorPermissions[permission?.key] = false;
            }
        }
    });

    return vendorPermissions;
}

const setWarehousePermissions = (action: any) => {
    const warehousePermissions: any = {};
    
    WAREHOUSE_MANAGEMENT.forEach((permission: any) => {
        if(action?.payload === "SUPER_ADMIN") {
            warehousePermissions[permission?.key] = true;
        } else {
            if(action.payload.includes(permission?.name)) {
                warehousePermissions[permission?.key] = true;
            } else {
                warehousePermissions[permission?.key] = false;
            }
        }
    });

    return warehousePermissions;
}

const permissionSlice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        setPermissions: (state, action: PayloadAction<any>) => {
            state.analytics = setAnalyticsPermissions(action);
            state.brand = setBrandPermissions(action);
            state.inventory = setInventoryPermissions(action);
            state.order = setOrderPermissions(action);
            state.orderbot = setOrderBotPermissions(action);
            state.paymentTerm = setPaymentTermPermissions(action);
            state.product = setProductPermissions(action);
            state.purchaseOrder = setPurchaseOrderPermissions(action);
            state.roleRight = setRoleRightPermissions(action);
            state.salesChannel = setSalesChannelPermissions(action);
            state.shipment = setShipmentPermissions(action);
            state.shippingTerm = setShippingTermPermissions(action);
            state.user = setUserPermissions(action);
            state.vendor = setVendorPermissions(action);
            state.warehouse = setWarehousePermissions(action);
        }
    },
});

export const { setPermissions } = permissionSlice.actions;

// Persist Configuration
const persistConfig = {
    key: 'permissions',
    storage: storageSession, // Change to 'storage' for localStorage persistence
};

export default persistReducer(persistConfig, permissionSlice.reducer);
