import { createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../../services/interceptor';
import { API_PATH } from '../../constants/api-path';

// Define API call using Thunk
export const fetchBrands = createAsyncThunk(
    'brands/fetchBrands', // Action Type
    async (payloadData: any, { rejectWithValue }) => {
        try {
            const response = await httpClient.get(API_PATH.BRAND.LISTING, payloadData);
            if (!response) throw new Error('Failed to fetch brands');

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);
