import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import MIXTLogo from '../../../assets/images/logo.png';
import Loader from '../../../components/common/Loader';
import { GLOBAL_DEFAULT } from '../../../constants/global';
import { orderService } from '../services';
import DefaultImage from "../../../assets/images/default-image.jpg";

const PrintPickList: React.FC<any> = () => {
    const [state, setState] = useState<any>(null);
    const [orderDetail, setOrderDetail] = useState<any>();
    const [loading, setLoading] = useState<boolean>(GLOBAL_DEFAULT.LOADER_STATE);

    useEffect(() => {
        try {
            const windowName = window.name;
            const state = JSON.parse(windowName);

            setState(state);
        } catch (error) {
            console.error('Failed to parse state from window name', error);
            window.location.href = '/app/dashboard';
        }
    }, []);

    const getOrderDetails = useCallback(async () => {
        if (state?.orderId) {
            setLoading(true);
            try {
                const payload = {
                    orderIds: state.orderId
                }
                const response = await orderService.printPickList(payload);

                let item: any[] = [];

                for (const orders of response?.data) {
                    for (const order of orders?.order_item_info) {
                        const orderItem = item?.find((odr: any) => order?.master_sku === odr?.master_sku && order?.warehouse_location_id === odr?.warehouse_location_id);
                        if (orderItem) {
                            orderItem.quantity += order.quantity;
                        } else {
                            item.push(order);
                        }
                    }
                }

                setOrderDetail(item);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

    }, [state?.orderId]);

    useEffect(() => {
        getOrderDetails();

    }, [getOrderDetails]);

    return <>
        <Loader isLoading={loading} />
        <div className='text-center hide-on-print pt-3 '><Button className='custom-min-width-85' size='sm' variant="dark" onClick={() => window.print()}>Print</Button></div>
        <div style={{ pageBreakAfter: 'auto' }}>
            <table
                aria-hidden="true"
                style={{
                    fontFamily: '"Source Sans Pro", sans-serif',
                    fontSize: 14,
                    color: "#000000",
                    margin: "10px auto",
                    padding: 0,
                    width: "100%",
                    background: "#FFFFFF",
                    textAlign: "left",
                    borderCollapse: "collapse",
                    border: "1px solid #CCCCCC",
                    maxWidth: "700px",
                    wordBreak: "break-word"
                }}
            >
                <tbody>
                    <tr>
                        <td
                            style={{
                                background: "#FFFFFF",
                                borderBottom: "3px solid #005C99",
                                padding: "15px 20px",
                                textAlign: "center"
                            }}
                        >
                            <table
                                style={{
                                    fontFamily: '"Source Sans Pro", sans-serif',
                                    fontSize: 14,
                                    margin: "0 auto",
                                    padding: 0,
                                    border: 0,
                                    textAlign: "left",
                                    borderCollapse: "collapse",
                                    width: "100%",
                                    pageBreakInside: "avoid"
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th style={{ width: 117, verticalAlign: "middle" }}>
                                            <img
                                                src={MIXTLogo}
                                                alt="MIXT Logo"
                                                width={117}
                                                height={40}
                                                style={{ border: 0, display: "block" }}
                                            />
                                        </th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            <h1
                                                style={{
                                                    margin: 0,
                                                    fontWeight: "bold",
                                                    fontSize: 24,
                                                    color: "#005C99"
                                                }}
                                            >
                                                Pick List
                                            </h1>
                                        </th>
                                        <th
                                            style={{
                                                width: 117,
                                                textAlign: "right",
                                                verticalAlign: "middle"
                                            }}
                                        >
                                            &nbsp;
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: "20px 20px 0" }}>
                            <table
                                aria-hidden="true"
                                style={{
                                    fontSize: 14,
                                    margin: "0 auto",
                                    padding: 0,
                                    border: 0,
                                    textAlign: "left",
                                    borderCollapse: "collapse",
                                    width: "100%"
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "10px 0" }}>
                                            <table
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    textAlign: "left",
                                                    borderCollapse: "collapse",
                                                    width: "100%",
                                                    borderColor: "#CCCCCC",
                                                    border: "1px solid #CCCCCC"
                                                }}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th
                                                            style={{
                                                                fontWeight: "bold",
                                                                padding: "15px 10px",
                                                                verticalAlign: "top",
                                                                textAlign: "center",
                                                                width: '5%',
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #CCCCCC",
                                                                background: "#E6EFF5"
                                                            }}
                                                        >
                                                            S.No.
                                                        </th>
                                                        <th
                                                            style={{
                                                                fontWeight: "bold",
                                                                padding: "15px 10px",
                                                                verticalAlign: "top",
                                                                textAlign: "center",
                                                                width: '20%',
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #CCCCCC",
                                                                background: "#E6EFF5"
                                                            }}
                                                        >
                                                            SKU
                                                        </th>
                                                        <th
                                                            style={{
                                                                fontWeight: "bold",
                                                                padding: "15px 10px",
                                                                verticalAlign: "top",
                                                                width: "35%",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #CCCCCC",
                                                                background: "#E6EFF5"
                                                            }}
                                                        >
                                                            Product Name
                                                        </th>
                                                        {/* <th
                                                            style={{
                                                                fontWeight: "bold",
                                                                padding: "15px 10px",
                                                                verticalAlign: "top",
                                                                width: "30%",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #CCCCCC",
                                                                background: "#E6EFF5"
                                                            }}
                                                        >
                                                            Location
                                                        </th> */}
                                                        <th
                                                            style={{
                                                                fontWeight: "bold",
                                                                padding: "15px 10px",
                                                                verticalAlign: "top",
                                                                width: "20%",
                                                                textAlign: "right",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #CCCCCC",
                                                                background: "#E6EFF5"
                                                            }}
                                                        >
                                                            Qty
                                                        </th>
                                                        <th
                                                            style={{
                                                                fontWeight: "bold",
                                                                padding: "15px 10px",
                                                                verticalAlign: "top",
                                                                whiteSpace: "nowrap",
                                                                border: "1px solid #CCCCCC",
                                                                background: "#E6EFF5"
                                                            }}
                                                        >
                                                            Product Image
                                                        </th>
                                                    </tr>

                                                    {orderDetail?.map((item: any, index: number) => {
                                                        return <tr key={index}>
                                                            <td
                                                                style={{
                                                                    padding: "4px 10px",
                                                                    textAlign: "center",
                                                                    fontWeight: "normal",
                                                                    border: "1px solid #CCCCCC"
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding: "4px 10px",
                                                                    textAlign: "center",
                                                                    fontWeight: "normal",
                                                                    border: "1px solid #CCCCCC"
                                                                }}
                                                            >
                                                                {item.master_sku}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding: "4px 10px",
                                                                    fontWeight: "normal",
                                                                    border: "1px solid #CCCCCC"
                                                                }}
                                                            >
                                                                {item?.product_name}
                                                            </td>
                                                            {/* <td
                                                                style={{
                                                                    padding: "4px 10px",
                                                                    fontWeight: "normal",
                                                                    border: "1px solid #CCCCCC"
                                                                }}
                                                            >
                                                                {item?.warehouse_location_name}
                                                            </td> */}
                                                            <td
                                                                style={{
                                                                    padding: "4px 10px",
                                                                    textAlign: "right",
                                                                    fontWeight: "normal",
                                                                    border: "1px solid #CCCCCC"
                                                                }}
                                                            >
                                                                {item?.quantity}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    padding: "4px 10px",
                                                                    textAlign: "center",
                                                                    fontWeight: "normal",
                                                                    border: "1px solid #CCCCCC"
                                                                }}
                                                            >
                                                                <img
                                                                    src={item?.product_default_image || DefaultImage}
                                                                    alt="Product"
                                                                    width={40}
                                                                    height={40}
                                                                    style={{ border: 0, display: "block", maxWidth: '40px', maxHeight: '40px', objectFit: 'contain', margin: '0 auto' }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
}

export default PrintPickList;