import httpClient from '../../../services/interceptor';

import { API_PATH } from '../../../constants/api-path';

export const salesChannelService = {
	/* addShopifySalesChannel: (): Promise<any> => {
		return httpClient.get(API_PATH.SALESCHANNEL.ADDSHOPIFY);
	}, */

	addShopifySalesChannel: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.ADDSHOPIFY, payloadData);
	},

	addAmazonSalesChannel: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.ADDAMAZON, payloadData);
	},

	addWalmartSalesChannel: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.ADDWALMART, payloadData);
	},

	amazonIntegration: (type: string): Promise<any> => {
		const query = '?type='+type;
		return httpClient.get(API_PATH.SALESCHANNEL.ADDAMAZON+query);
	},

	amazonCallback: (amazonDetail: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.AMAZONCALLBACK, amazonDetail);
	},

	editSalesChannel: (salesChannelId: any, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.SALESCHANNEL.EDIT + salesChannelId, payloadData);
	},

	refreshSalesChannelInventoryById: (salesChannelId: any): Promise<any> => {
		return httpClient.get(API_PATH.SALESCHANNEL.REFRESH_INVENTORY + salesChannelId);
	},

	checkShopifySalesChannelExist: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.CHECKSHOPIFYSALESCHANNELEXIST, requestData);
	},

	installationShopifySalesChannel: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.INSTALLATIONSHOPIFY, requestData);
	},

	getSalesChannelListing: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.SALESCHANNEL.LISTING, payloadData);
	},

	getSalesChannelHisotry: (salesChannelId: number, queryParams: any): Promise<any> => {
		let query: any = queryParams.page ? `?page=${queryParams.page}` : '';
		query += `&row_per_page=${queryParams.row_per_page}`;
		
		return httpClient.get(API_PATH.SALESCHANNEL.HISTORY + salesChannelId + query);
	},

	updateSalesChannelStatusById: (salesChannelId: number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.SALESCHANNEL.STATUS + salesChannelId, payloadData);
	}
}