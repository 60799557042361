import React, { useCallback, useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import MIXTLogo from '../../../assets/images/logo.png';
import { purchaseOrderService } from '../services';
import moment from 'moment';
import constants from '../../../constants/constants';
import Loader from '../../../components/common/Loader';
import { Button, Stack } from 'react-bootstrap';
import { MdInventory2 } from 'react-icons/md';

const RenderInnerHTML = (props: any) => (<span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>);

const POPendingItems: React.FC<any> = () => {
    const [state, setState] = useState<any>(null);
    const [poDetail, setPODetail] = useState<any>();
    const [poPendingItems, setPOPendingItems] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    let itemIndex: number = 0;

    useEffect(() => {
        try {
            const windowName = window.name;
            const state = JSON.parse(windowName);
            setState(state);
        } catch (error) {
            console.error('Failed to parse state from window name', error);
        }
    }, []);

    const getPurchaseOrderDetail = useCallback(async () => {
        if (state?.poId) {
            setLoading(true);
            try {
                const response = await purchaseOrderService.getPurchaseOrderDetail(state?.poId[0]);

                setPODetail(response?.data);

                const purchaseOrderItems = response?.data?.purchase_order_items || [];

                const purchase_order_items = purchaseOrderItems?.map((poItem: any) => {
                    const poData = poItem;

                    /* if (poItem.product_type === 'Bundle') {
                        const data = poItem?.core_products.map((item: any) => { return { ...item, bundle_id: poItem?.id } });
                        return [poItem, ...data]
                    } */
                    
                    return	poData;
                })
                .flat(1).filter((item: any) => (item.status === 0));

                setPOPendingItems(purchase_order_items)
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

    }, [state?.poId]);

    useEffect(() => {
        getPurchaseOrderDetail();

    }, [getPurchaseOrderDetail]);

    return <>
        <Loader isLoading={loading} />
        <div className='text-center hide-on-print pt-3'><Button className='custom-min-width-85' size='sm' variant="dark" onClick={() => window.print()}>Print</Button></div>
        <table
            aria-hidden="true"
            style={{
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: 14,
                color: "#000000",
                margin: "10px auto",
                padding: 0,
                width: "100%",
                background: "#FFFFFF",
                textAlign: "left",
                borderCollapse: "collapse",
                border: "1px solid #CCCCCC",
                maxWidth: "700px",
                wordBreak: "break-word"
                // height: "90vh"
            }}
        >
            <tbody>
                {/* Header */}
                <tr>
                    <td
                        style={{
                            background: "#FFFFFF",
                            borderBottom: "3px solid #005C99",
                            padding: "15px 20px",
                            textAlign: "center"
                        }}
                    >
                        <table
                            style={{
                                fontFamily: '"Source Sans Pro", sans-serif',
                                fontSize: 14,
                                margin: "0 auto",
                                padding: 0,
                                border: 0,
                                textAlign: "left",
                                borderCollapse: "collapse",
                                width: "100%",
                                pageBreakInside: "avoid"
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: 117, verticalAlign: "middle" }}>
                                        <img
                                            src={MIXTLogo}
                                            alt="MIXT Logo"
                                            width={117}
                                            height={40}
                                            style={{ border: 0, display: "block" }}
                                        />
                                    </th>
                                    <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        <h1
                                            style={{
                                                margin: 0,
                                                fontWeight: "bold",
                                                fontSize: 24,
                                                color: "#005C99"
                                            }}
                                        >
                                            Purchase Order
                                        </h1>
                                    </th>
                                    <th
                                        style={{
                                            width: 117,
                                            textAlign: "right",
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        {poDetail?.po_number && <Barcode value={poDetail?.po_number} width={1}
                                            height={30}
                                            format={"CODE128"}
                                            displayValue={true}
                                            fontSize={14}
                                        />}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </td>
                </tr>
                {/* Body Content */}
                <tr>
                    <td style={{ padding: "20px 20px 0" }}>
                        <table
                            aria-hidden="true"
                            style={{
                                fontSize: 14,
                                margin: "0 auto",
                                padding: 0,
                                border: 0,
                                textAlign: "left",
                                borderCollapse: "collapse",
                                width: "100%"
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td style={{ padding: "0 0 10px" }}>
                                        <table
                                            aria-hidden="true"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                textAlign: "left",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                                borderColor: "#CCCCCC",
                                                border: "1px solid #CCCCCC",
                                                pageBreakInside: "avoid",
                                                tableLayout: "fixed"
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                        <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                            PO No.
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>{poDetail?.po_number}</div>
                                                    </td>
                                                    <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                        <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                            Order Date
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            {poDetail?.createdAt ? moment(new Date(poDetail?.createdAt)).format(constants.datetimeFormats.dateTime) : '-'}
                                                        </div>
                                                    </td>
                                                    <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                        <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                            Confirmed On
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            {poDetail?.confirm_date ? moment(new Date(poDetail?.confirm_date)).format(constants.datetimeFormats.dateTime) : '-'}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={2}
                                                        style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}
                                                    >
                                                        <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                            Shipping Terms
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>{poDetail?.shipping_terms?.name}</div>
                                                    </td>
                                                    <td style={{ padding: 10, border: "1px solid #CCCCCC", verticalAlign: "top" }}>
                                                        <div style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                            Payment Terms
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>{poDetail?.payment_term?.name}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: 0 }}>
                                        <table
                                            aria-hidden="true"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                textAlign: "left",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                                pageBreakInside: "avoid",
                                                tableLayout: "fixed",
                                                border: "1px solid #CCCCCC",
                                                background: "#E6EFF5"
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: 10,
                                                            verticalAlign: "top",
                                                            border: "1px solid #CCCCCC"
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: "bold",
                                                                color: "#005C99",
                                                                marginBottom: 4
                                                            }}
                                                        >
                                                            Supplier Details
                                                        </h5>
                                                        <div
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                marginBottom: 2
                                                            }}
                                                        >
                                                            {poDetail?.vendor?.name}
                                                        </div>
                                                        <div style={{ marginBottom: 6 }}>
                                                            <RenderInnerHTML HTML={poDetail?.vendor_address} /><br />
                                                            {poDetail?.vendor?.email}<br />
                                                            {poDetail?.vendor?.phone}
                                                        </div>
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: 10,
                                                            verticalAlign: "top",
                                                            border: "1px solid #CCCCCC"
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: "bold",
                                                                color: "#005C99",
                                                                marginBottom: 4
                                                            }}
                                                        >
                                                            Buyer (Bill to)
                                                        </h5>
                                                        <div
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                marginBottom: 2
                                                            }}
                                                        >
                                                            {poDetail?.buyer_name}
                                                        </div>
                                                        <div style={{ marginBottom: 6 }}>
                                                            <RenderInnerHTML HTML={poDetail?.buyer_address} />
                                                        </div>
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: 10,
                                                            verticalAlign: "top",
                                                            border: "1px solid #CCCCCC"
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                fontSize: 16,
                                                                fontWeight: "bold",
                                                                color: "#005C99",
                                                                marginBottom: 4
                                                            }}
                                                        >
                                                            Consignee (Ship to)
                                                        </h5>
                                                        <div
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                marginBottom: 2
                                                            }}
                                                        >
                                                            {poDetail?.warehouse?.name}
                                                        </div>
                                                        <div style={{ marginBottom: 6 }}>
                                                            <RenderInnerHTML HTML={poDetail?.warehouse_address} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "10px 0" }}>
                                        <table
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                textAlign: "left",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                                pageBreakInside: "avoid",
                                                borderColor: "#CCCCCC",
                                                border: "1px solid #CCCCCC"
                                            }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: "15px 10px",
                                                            verticalAlign: "top",
                                                            textAlign: "center",
                                                            width: 62,
                                                            whiteSpace: "nowrap",
                                                            border: "1px solid #CCCCCC",
                                                            background: "#E6EFF5"
                                                        }}
                                                    >
                                                        S. No.
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: "15px 10px",
                                                            verticalAlign: "top",
                                                            whiteSpace: "nowrap",
                                                            border: "1px solid #CCCCCC",
                                                            background: "#E6EFF5"
                                                        }}
                                                    >
                                                        Vendor SKU{" "}
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: "15px 10px",
                                                            verticalAlign: "top",
                                                            whiteSpace: "nowrap",
                                                            border: "1px solid #CCCCCC",
                                                            background: "#E6EFF5"
                                                        }}
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: "15px 10px",
                                                            verticalAlign: "top",
                                                            whiteSpace: "nowrap",
                                                            border: "1px solid #CCCCCC",
                                                            background: "#E6EFF5"
                                                        }}
                                                    >
                                                        UPC
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: "15px 10px",
                                                            verticalAlign: "top",
                                                            textAlign: "right",
                                                            whiteSpace: "nowrap",
                                                            border: "1px solid #CCCCCC",
                                                            background: "#E6EFF5"
                                                        }}
                                                    >
                                                        Qty.
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {poPendingItems?.map((item: any, index: number) => {
                                                    itemIndex = !item?.bundle_id ? itemIndex + 1 : itemIndex;
                                                    return <tr key={index}>
                                                        <td
                                                            style={{
                                                                padding: "4px 10px",
                                                                textAlign: "center",
                                                                fontWeight: "normal",
                                                                border: "1px solid #CCCCCC"
                                                            }}
                                                        >
                                                            {!item.bundle_id && itemIndex}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px 10px",
                                                                fontWeight: "normal",
                                                                border: "1px solid #CCCCCC"
                                                            }}
                                                        >
                                                            {item.product_type === 'Bundle' 
                                                                ? <Stack direction='horizontal' gap={1}><MdInventory2 size={20} className='d-block' />{item?.product_vendor?.vendor_sku}</Stack> 
                                                                : (item?.bundle_id 
                                                                    ? <Stack direction='horizontal' gap={1}>
                                                                        (<b>{item.quantity}x</b>)&nbsp;
                                                                        {item?.product_vendor?.vendor_sku}
                                                                        </Stack>
                                                                    :<Stack direction='horizontal' gap={1}>
                                                                        {item?.product_vendor?.vendor_sku}
                                                                        </Stack>
                                                                )
                                                            }
                                                            {/* {item?.product_vendor?.vendor_sku} */}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px 10px",
                                                                fontWeight: "normal",
                                                                border: "1px solid #CCCCCC"
                                                            }}
                                                        >
                                                            {item?.product?.name}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px 10px",
                                                                fontWeight: "normal",
                                                                border: "1px solid #CCCCCC"
                                                            }}
                                                        >
                                                            {item?.product?.upc || '-'}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px 10px",
                                                                textAlign: "right",
                                                                fontWeight: "normal",
                                                                border: "1px solid #CCCCCC"
                                                            }}
                                                        >
                                                            {item?.total_quantity}
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr hidden={!poDetail?.message_to_vendor}>
                                    <td
                                        style={{
                                            padding: 10,
                                            border: "1px solid #CCCCCC",
                                            background: "#E6EFF5",
                                            verticalAlign: "top",
                                            pageBreakInside: "avoid"
                                        }}
                                    >
                                        <strong style={{ fontWeight: "bold" }}>
                                            Notes To Vendor:{" "}
                                        </strong>
                                        {poDetail?.message_to_vendor}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                {/* Footer */}
                <tr>
                    <td style={{ padding: 20 }}>
                        <p style={{ margin: "0 0 12px" }}>
                            {`Authorized By:  ${poDetail?.authorize_by?.name || '-' } ${poDetail?.authorize_date ? 'On Date: ' + moment(new Date(poDetail?.authorize_date)).format(constants.datetimeFormats.dateTime) : ''}`}
                        </p>
                        <p style={{ margin: "0 0 12px", fontSize: 16 }}>
                            <strong style={{ fontWeight: "bold" }}>Thank You,</strong>
                            <br />
                            Team Mixt Solutions
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </>;
}

export default POPendingItems;