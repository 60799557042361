import moment from 'moment';
import React, { FC } from 'react'
import constants from '../../../constants/constants';
import MIXTLogo from '../../../assets/images/logo.png';
import { formatCost, truncateText } from '../../../helpers/utils';
import Barcode from 'react-barcode';

interface IPrintPackaginSlipMIXT {
    orderDetails: any,
}

const RenderInnerHTML = (props: any) => (<span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>);

const PrintPackaginSlipMIXT: FC<IPrintPackaginSlipMIXT> = ({
    orderDetails
}) => {
    return (
        <table
            aria-hidden="true"
            style={{
                fontFamily: '"Source Sans Pro", sans-serif',
                fontSize: 12,
                color: "#000000",
                margin: "10px auto",
                padding: 0,
                width: "576px",
                background: "#FFFFFF",
                textAlign: "left",
                borderCollapse: "collapse",
                maxWidth: "100%",
                wordBreak: "break-word",
                pageBreakInside: 'avoid',
            }}
        >
            <tbody>
                {/* Header */}
                <tr>
                    <td
                        style={{
                            background: "#FFFFFF",
                            borderBottom: "3px solid #005C99",
                            padding: "8px 0",
                            textAlign: "center"
                        }}
                    >
                        <table
                            style={{
                                fontFamily: '"Source Sans Pro", sans-serif',
                                fontSize: 12,
                                margin: "0 auto",
                                padding: 0,
                                border: 0,
                                textAlign: "left",
                                borderCollapse: "collapse",
                                width: "100%",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th style={{ width: 80, verticalAlign: "middle" }}>
                                        <img
                                            src={MIXTLogo}
                                            alt="MIXT Logo"
                                            width={80}
                                            height={28}
                                            style={{ border: 0, display: "block" }}
                                        />
                                    </th>
                                    <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                        <h1
                                            style={{
                                                margin: 0,
                                                fontWeight: "bold",
                                                fontSize: 18,
                                                color: "#005C99"
                                            }}
                                        >
                                            Packing Slip
                                        </h1>
                                    </th>
                                    <th
                                        style={{
                                            width: 80,
                                            textAlign: "right",
                                            verticalAlign: "middle"
                                        }}
                                    >
                                        &nbsp;
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </td>
                </tr>
                {/* Body Content */}
                <tr>
                    <td style={{ padding: "8px 0 0" }}>
                        <table
                            aria-hidden="true"
                            style={{
                                fontSize: 12,
                                margin: "0 auto",
                                padding: 0,
                                border: 0,
                                textAlign: "left",
                                borderCollapse: "collapse",
                                width: "100%"
                            }}
                        >
                            <tbody>

                                <tr>
                                    <td
                                        style={{
                                            textAlign: "right",
                                            verticalAlign: "middle"
                                        }}>
                                        {orderDetails?.order_number && <Barcode value={orderDetails?.order_number} width={1}
                                            height={20}
                                            format={"CODE128"}
                                            displayValue={true}
                                            fontSize={12}
                                        />}
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ padding: '8px 0 0' }}>
                                        <table
                                            aria-hidden="true"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                textAlign: "left",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                                tableLayout: "fixed",
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: 4,
                                                            verticalAlign: "top",
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                color: "#005C99",
                                                                marginBottom: 4
                                                            }}
                                                        >
                                                            Shipping Details
                                                        </h5>
                                                        <div
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: "bold",
                                                                marginBottom: 2
                                                            }}
                                                        >
                                                            {orderDetails?.recipient?.name}
                                                        </div>
                                                        <div style={{ marginBottom: 6 }}>
                                                            <RenderInnerHTML HTML={orderDetails?.recipient?.address + ', ' + (orderDetails?.recipient?.address_2 ? orderDetails?.recipient?.address_2 + ', ' : '') + '<br />' + orderDetails?.recipient?.city + ', ' + orderDetails?.recipient?.state + ', ' + orderDetails?.recipient?.country + ', ' + orderDetails?.recipient?.zip_code} /> <br />
                                                            {orderDetails?.recipient?.phone}
                                                        </div>
                                                    </td>

                                                    <td
                                                        style={{
                                                            padding: 4,
                                                            verticalAlign: "top",
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                fontSize: 14,
                                                                fontWeight: "bold",
                                                                color: "#005C99",
                                                                marginBottom: 4
                                                            }}
                                                        >
                                                            Order Details
                                                        </h5>

                                                        <div>
                                                            Order No:&nbsp;
                                                            <span
                                                                style={{
                                                                    fontSize: 12,
                                                                    marginBottom: 2,
                                                                    fontWeight: "bold"
                                                                }}
                                                            >
                                                                {orderDetails?.order_number}
                                                            </span>
                                                        </div>
                                                        {orderDetails?.order_date &&
                                                            <div>
                                                                Order Date:&nbsp;
                                                                <span
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontWeight: "bold",
                                                                        marginBottom: 2
                                                                    }}
                                                                >
                                                                    {orderDetails?.order_date ? moment(new Date(orderDetails?.order_date)).format(constants.datetimeFormats.date) : ''}
                                                                </span>
                                                            </div>
                                                        }
                                                        {orderDetails?.shipment_date && orderDetails?.status === 4 &&
                                                            <div>
                                                                Shipped Date:&nbsp;
                                                                <span
                                                                    style={{
                                                                        fontSize: 12,
                                                                        fontWeight: "bold",
                                                                        marginBottom: 2
                                                                    }}
                                                                >
                                                                    {orderDetails?.shipment_date ? moment(new Date(orderDetails?.shipment_date)).format(constants.datetimeFormats.date) : ''}
                                                                </span>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: "8px 0" }}>
                                        <table
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                textAlign: "left",
                                                borderCollapse: "separate",
                                                width: "100%",
                                                borderSpacing: '2px',
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 4,
                                                            width: 60,
                                                            whiteSpace: "nowrap",
                                                            borderBottom: '1px solid #000',
                                                        }}
                                                    >
                                                        SKU
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 4,
                                                            width: "30%",
                                                            whiteSpace: "nowrap",
                                                            borderBottom: '1px solid #000',
                                                        }}
                                                    >
                                                        Product Name
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 4,
                                                            textAlign: "right",
                                                            borderBottom: '1px solid #000',
                                                        }}
                                                    >
                                                        Unit Price
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 4,
                                                            textAlign: "right",
                                                            whiteSpace: "nowrap",
                                                            borderBottom: '1px solid #000',
                                                        }}
                                                    >
                                                        Qty
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 4,
                                                            textAlign: "right",
                                                            borderBottom: '1px solid #000',
                                                        }}
                                                    >
                                                        Tax Paid
                                                    </th>
                                                    <th
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 4,
                                                            textAlign: "right",
                                                            borderBottom: '1px solid #000',
                                                        }}
                                                    >
                                                        Final Cost
                                                    </th>
                                                </tr>

                                                {orderDetails?.order_item_info.map((item: any, index: number) => {
                                                    return <tr key={index}>
                                                        <td
                                                            style={{
                                                                padding: "4px",
                                                                fontWeight: "normal",
                                                                borderBottom: '1px solid #000',
                                                            }}
                                                        >
                                                            {item.master_sku}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px",
                                                                fontWeight: "normal",
                                                                borderBottom: '1px solid #000',
                                                            }}
                                                        >
                                                            {item?.product_name ? truncateText(item?.product_name, 100) : "-"}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px",
                                                                textAlign: "right",
                                                                fontWeight: "normal",
                                                                borderBottom: '1px solid #000',
                                                            }}
                                                        >
                                                            {formatCost(item?.unit_cost)}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px",
                                                                textAlign: "right",
                                                                fontWeight: "normal",
                                                                borderBottom: '1px solid #000',
                                                            }}
                                                        >
                                                            {item?.quantity}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px",
                                                                textAlign: "right",
                                                                fontWeight: "normal",
                                                                borderBottom: '1px solid #000',
                                                            }}
                                                        >
                                                            {formatCost('0.00')}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: "4px",
                                                                textAlign: "right",
                                                                fontWeight: "normal",
                                                                borderBottom: '1px solid #000',
                                                            }}
                                                        >
                                                            {formatCost(item?.total_cost)}
                                                        </td>
                                                    </tr>
                                                })}
                                                <tr>
                                                    <td
                                                        colSpan={6}
                                                        style={{
                                                            padding: 4,
                                                            fontSize: 12,
                                                            fontWeight: "bold",
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        Total: ${formatCost(orderDetails?.order_total)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default PrintPackaginSlipMIXT;