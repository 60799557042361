import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from '../../helpers/storage';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

interface ThemeState {
    theme: 'light' | 'dark';
    language: string;
}

const initialState: ThemeState = {
    theme: (storage.getData('theme') as 'light' | 'dark') || 'light',
    language: 'en', // Default language
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        updateTheme: (state) => {
            state.theme = state.theme === 'light' ? 'dark' : 'light';
            // storage.setData('theme', state.theme);
        },
        setTheme: (state, action: PayloadAction<'light' | 'dark'>) => {
            state.theme = action.payload;
            // storage.setData('theme', action.payload);
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
    },
});

export const { updateTheme, setTheme, setLanguage } = themeSlice.actions;
// export default themeSlice.reducer;

// Persist Configuration
const persistConfig = {
    key: 'theme',
    storage: storageSession, // Change to 'storage' for localStorage persistence
};

export default persistReducer(persistConfig, themeSlice.reducer);
