import { API_PATH } from "../../constants/api-path";
import httpClient from "../../services/interceptor";


export const orderService = {

	getOrderListing: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.LISTING, payloadData);
	},

	getArchiveOrderListing: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.ARCHIVE_LIST, payloadData);
	},

	addManualOrder: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.ADD_MANUAL_ORDER, payloadData);
	},

	editManualOrder: (orderId: string, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.EDIT + orderId, payloadData);
	},

	assignToUser: (payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.ASSIGN_USER, payloadData);
	},

	ordersOnHold: (payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.ON_HOLD, payloadData);
	},

	ordersRestore: (payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.RESTORE, payloadData);
	},

	exportPO: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.EXPORT, requestData);
	},

	getOrderDetail: (orderId: string | number): Promise<any> => {
		return httpClient.get(API_PATH.ORDER.DETAIL + orderId)
	},

	getArchiveOrderDetail: (orderId: string | number): Promise<any> => {
		return httpClient.get(API_PATH.ORDER.ARCHIVE_DETAIL + orderId)
	},

	notesUpdate: (orderId: string | number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.NOTES_UPDATE + orderId, payloadData);
	},

	printPackingSlip: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.PACKING_SLIP, requestData);
	},

	printReturnLabel: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.RETURN_LABEL, requestData);
	},

	printPickList: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.PICK_LIST, requestData);
	},

	updateRecepient: (orderId: string | number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.EDIT_RECIEPIENT + orderId, payloadData);
	},

	markValidAddress: (orderId: string | number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.MARK_VALID_ADDRESS + orderId, payloadData);
	},

	getOrderHistory: (orderId: string | number, queryParams: any): Promise<any> => {
		let query: any = queryParams.page ? `?page=${queryParams.page}` : '';
		query += `&row_per_page=${queryParams.row_per_page}`;
		return httpClient.post(API_PATH.ORDER.HISTORY + orderId + query);
	},

	getArchiveOrderHistory: (orderId: string | number, queryParams: any): Promise<any> => {
		let query: any = queryParams.page ? `?page=${queryParams.page}` : '';
		query += `&row_per_page=${queryParams.row_per_page}`;
		return httpClient.post(API_PATH.ORDER.ARCHIVE_HISTORY + orderId + query);
	},

	exportOrder: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.EXPORT, requestData);
	},

	exportOrderBySearch: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.EXPORT_BY_FILTERS, payloadData);
	},

	exportArchiveOrderBySearch: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.ARCHIVE_EXPORT, payloadData);
	},

	getCarrierList: (): Promise<any> => {
		return httpClient.get(API_PATH.COMMON.CARRIER_LIST);
	},

	getEstimateShipRate: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.ESTIMATE_SHIP_RATE, requestData);
	},

	getAdditionalShippingRate: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.ADDITIONAL_SHIP_RATE, requestData);
	},

	saveShippingRateProvider: (orderId: any, requestData: any): Promise<any> => {
		return httpClient.patch(`${API_PATH.ORDER.SAVE_SHIPPING_RATE_PROVIDER}${orderId}`, requestData);
	},

	orderShip: (requestData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.SHIP, requestData);
	},

	orderCancel: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.CANCEL, requestData);
	},

	missingOrderAttach: (requestData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.MISSING_ORDER, requestData);
	},

	updateDimensions: (orderId: string | number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.UPDATE_DIMENSIONS + orderId, payloadData);
	},

	updatePackageDetails: (orderId: string | number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.UPDATE_MULTIPLE_PACKAGE + orderId, payloadData);
	},
	
	createDuplicateOrder: (payload: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.CREATE_DUPLICATE_ORDER, payload);
	},

	updateCustomsData: (orderId: string | number, payloadData: any): Promise<any> => {
		return httpClient.patch(API_PATH.ORDER.UPDATE_CUSTOMS, payloadData);
	},

	orderMoveToAwaitingShipment: (payloadData: any): Promise<any> => {
		return httpClient.post(API_PATH.ORDER.MOVE_TO_AWAITING_SHIPMENT, payloadData);
	},
}