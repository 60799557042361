import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from '../../../components/common/Loader';
import { GLOBAL_DEFAULT } from '../../../constants/global';
import { orderService } from '../services';
import PrintPackaginSlipFitCrunch from './printPackaginSlipFitCrunch';
import PrintPackaginSlipMIXT from './printPackagingSlipMIXT';

const PrintPackagingSlip: React.FC<any> = () => {
    const [state, setState] = useState<any>(null);
    const [orderDetail, setOrderDetail] = useState<any>();
    const [loading, setLoading] = useState<boolean>(GLOBAL_DEFAULT.LOADER_STATE);

    useEffect(() => {
        try {
            const windowName = window.name;
            const state = JSON.parse(windowName);

            setState(state);
        } catch (error) {
            console.error('Failed to parse state from window name', error);
            window.location.href = '/app/dashboard';
        }
    }, []);

    const getOrderDetail = useCallback(async () => {
        if (state?.orderId) {
            setLoading(true);
            try {
                const payload = {
                    orderIds: state?.orderId,
                    type: state?.orderType || 'Orders'
                }
                const response = await orderService.printPackingSlip(payload);

                setOrderDetail(response?.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

    }, [state?.orderId, state?.orderType]);

    useEffect(() => {
        getOrderDetail();

    }, [getOrderDetail]);

    return <>
        <Loader isLoading={loading} />
        <div className='text-center hide-on-print pt-3'><Button className='custom-min-width-85' size='sm' variant="dark" onClick={() => window.print()}>Print</Button></div>
        {orderDetail?.map((orderDetails: any, index: any) => (
            <div key={index} style={{ pageBreakBefore: 'always', lineHeight: 1.2 }}>
                {
                    [43, 46, 47, 83, 84, 85].includes(orderDetails?.sales_channel_id) ?
                        <PrintPackaginSlipFitCrunch orderDetails={orderDetails} />
                        :
                        <PrintPackaginSlipMIXT orderDetails={orderDetails} />
                }
            </div>
        ))}
    </>;
}

export default PrintPackagingSlip;